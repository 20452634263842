import { Link } from "gatsby"
import React, { Fragment, useContext } from "react"
import { domain } from "../../utils"
import stars from "../../images/stars.svg"
import { GlobalStateContext } from "../../context/GlobalContextProvider"

export const NavbarLanding = ({ header }) => {
  const { logo } = header
  const state = useContext(GlobalStateContext)
  const { language } = state

  return (
    <Fragment>
      <header className="partial-navbar-custom">
        <div className="nav">
          <div className="container-fluid py-3 py-md-4">
            <div className="row m-auto">
              <div className="col-md-10 offset-md-1 col-12">
                <div className="row">
                  <div className="col-3 col-md-2 p-0">
                    <Link to={`/${language}/`}>
                      <img
                        className="logo"
                        src={`${domain}${logo.url}`}
                        alt="Coopz Logo"
                        width="auto"
                      ></img>
                    </Link>
                  </div>
                  <div className="col-9 col-md-10">
                    <div className="text-right">
                      <div className="d-inline-block mr-3 text-left">
                        <div className="rating">
                          <img src={stars} alt="stars" />
                        </div>
                        <div>
                          <p className="small m-0">
                            <b className="text-primary mr-1">4,4</b>
                            Store Rating
                          </p>
                        </div>
                      </div>
                      <div className="d-inline-block">
                        <h5 className="text-primary m-0">1.3.5</h5>
                        <p className="small m-0">Version</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </Fragment>
  )
}
