import React, { Fragment, useEffect, useContext } from "react";
import { graphql } from "gatsby";
import classnames from 'classnames';
import SEO from "../components/layout/seo";
import { BannerReverse } from "../components/partials/banner/bannerReverse";
import { Footer } from "../components/layout/footer";
import { NavbarLanding } from "../components/layout/navbarLanding";
import { GlobalDispatchContext } from "../context/GlobalContextProvider";
import { domain } from "../utils";
import { Link } from "@reach/router";

const LandingPageHub = ({ data }) => {
  const { landingpagehub, homepages } = data.strapi;
  const {
    language,
    banner,
    headline,
    cities,
    meta,
  } = landingpagehub;
  const { header, footer } = homepages[0];

  const dispatch = useContext(GlobalDispatchContext);
  useEffect(() => {
    dispatch({ type: "SET_LANGUAGE", language });
  });

  const AOS = require("aos");
  useEffect(() => {
    AOS.init({
      duration: 600,
    });
  });
  useEffect(() => {
    AOS.refresh();
  });
  
  return (
    <Fragment>
      <NavbarLanding header={header}/>
      <SEO title={meta.title} description={meta.description}/>
      <BannerReverse banner={banner} />
      <section>
        <div className="container-fluid">
          <div className="row mt-2 mb-5">
            <div className="col-md-10 offset-md-1 col-12">
              <h3 className="text-uppercase text-center">{headline}</h3>
              <div className="row">
                {cities.map((city, index) => {
                  const key = city.title;
                  return (
                    <div key={key} className="col-12 col-md-6 mt-4" data-aos="fade-up" data-aos-once="true">
                      <Link to={`${language}/${city.url}`} className="hub-card">
                        <div className={classnames('card-hover up',index % 2 ? 'pl-md-3 pl-lg-5' : 'pr-md-3 pr-lg-5')}>
                          <img className="w-100 img-shadow" src={`${domain}${city.img.url}`} alt={city.title}/>
                          <div className="my-3">
                            <h4>
                              {city.title}
                            </h4>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer footer={footer}/>
    </Fragment>
  )
}

export default LandingPageHub

export const pageQuery = graphql`
  query LandingPageHub($id: ID!, $language: STRAPI_JSON!) {
    strapi {
      landingpagehub(id: $id) {
        language
        banner {
          description
          title
        }
        headline
        cities {
          img {
            url
          }
          url
          title
        }
        meta {
          title
          description
        }
      }
      homepages(where: $language) {
        header {
          links {
            label
            slug
          }
          logo {
            url
          }
        }
        footer {
          title
          copyright
          logo {
            url
          }
          nav {
            heading
            link {
              label
              slug
            }
          }
        }
      }
    }
  }
`
